import Container from 'modules/common/Container';
import NewDesignButton from 'modules/common/NewDesignButton';
import React from 'react';
import {FormattedMessage} from 'react-intl';

const NotFoundPage = () => (
  <Container className="pv7-ns pv6 bg-white tc">
    <h1 className="pb3 pt5">
      <FormattedMessage id="404_title" />
    </h1>
    <p className="pv4 f5">
      <FormattedMessage id="404_text" />
    </p>
    <div className="pt4 pb1">
      <NewDesignButton className="pv3 ph3 f6 bg-button-blue" link="/tracks">
        <FormattedMessage id="return_to_tracks" />
      </NewDesignButton>
    </div>
  </Container>
);

export default NotFoundPage;
